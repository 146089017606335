.block-about {
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-xxs);
    --img-height: #{rem(312)};
    --img-width: #{rem(312)};
    --font-size: var(--font-size-xsmall);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-m);
        --img-height: #{rem(180)};
        --img-width: #{rem(180)};
        --font-size: var(--font-size-xxxsmall);
    }
}

.block-about {    
    .image {
        height: var(--img-height);
        width: var(--img-width);
        pointer-events: none;
        position: relative;
    }
    
    figure {
        @include z-index(1);
        left: 0;
        top: 0;
        height: 100%;
        position: absolute;
        width: 100%;
        
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    
    .overlay {
        @include z-index(2);
        left: 0;
        top: 0;
        position: absolute;
        height: var(--img-height);
        width: var(--img-width);
        pointer-events: none;
    }

    &__content {
        @include font-sans();
        font-size: var(--font-size);

        > div {
            margin-bottom: var(--padding-m);
            
            > ul {
                &:not(:last-child) {
                    margin-bottom: var(--padding-l);
                }

                .title {
                    text-transform: uppercase;
                    color: var(--black);
                    margin-bottom: var(--padding-xxxs);
                }

                > li {
                    margin-bottom: var(--padding-s);

                    li {
                        color: var(--black45);
                        padding-left: rem(40);
                    }
                }

                &.--with-margin {
                    .title {
                        margin-bottom: var(--padding-xxs);
                    }
                    
                    li li {
                        margin-bottom: var(--padding-xs);
                        text-indent: rem(-40);
                    }
                }
            }
        }
        
        > div:first-child {
            margin-bottom: var(--padding-l);
        
            p:not(:first-child) {
                text-indent: rem(40);
            }
        }
    }

    @media (max-width: $smartphone) {
        padding: calc(var(--padding-v) + var(--header-height)) var(--padding-h) var(--padding-v) ; 

        > div:first-child {
            margin-bottom: var(--padding-s);
        }
    }

    @media (min-width: $smartphone) {
        display: flex; 
        align-items: flex-start;
        padding: var(--padding-v) var(--padding-h) var(--padding-v) ; 
    
        > * {
            flex: 0 1 50%;
            padding-top: var(--header-height);
        }
    }
}
