@use "sass:math";

:root {
    --font-sans: 'Rand', sans-serif;

    // --font-size-xxlarge: #{math.div(42px, 16px) * 1rem};
    // --font-size-xlarge: #{math.div(36px, 16px) * 1rem};
    // --font-size-large: #{math.div(32px, 16px) * 1rem};
    --font-size-medium: #{math.div(36px, 16px) * 1rem};
    // --font-size-base: #{math.div(24px, 16px) * 1rem};
    // --font-size-small: #{math.div(20px, 16px) * 1rem};
    --font-size-xsmall: #{math.div(18px, 16px) * 1rem};
    // --font-size-xxsmall: #{math.div(16px, 16px) * 1rem};
    --font-size-xxxsmall: #{math.div(12px, 16px) * 1rem};
    // --font-size-xxxxsmall: #{math.div(12px, 16px) * 1rem};
    // --font-size-xxxxxsmall: #{math.div(8px, 16px) * 1rem};

    --line-height-sans: 1.2333333;
    --letter-spacing: -0.06em;

    --min-font-size: 10px;
    --max-font-size: 48px;
    // --font-size: clamp(var(--min-font-size), 1.1111vw * var(--zoom), var(--max-font-size));
    // --font-size: clamp(var(--min-font-size), 0.833333333vw * var(--zoom), var(--max-font-size));
    --font-size: 0.833333333vw * var(--zoom);
    font-size: var(--font-size);
    
    @media (max-width: $smartphone) {
        --font-size: 16px;
    }
}

@mixin font-sans($line-height:0) {
    font-family: var(--font-sans);
    font-weight: bold;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-sans-italic($line-height:0) {
    font-family: var(--font-sans);
    font-weight: bold;
    font-style: italic;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}
