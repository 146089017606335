

@use "sass:math";

.footer {
    --font-size: var(--font-size-xsmall);
    --padding-v: var(--padding-xxs);
    --padding-h: var(--padding-xxs);

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xxxsmall);
    }
}

.footer  {
    @include font-sans();
    
    font-size: var(--font-size);

    a {
        @include basic-a();
        color: currentColor;
    } 

    &:not(.--auto) {
        padding: var(--padding-v) var(--padding-h);
    }

    &.--fixed {
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    @include isCursor() {
        a:hover {
            color: var(--blue);
        }
    }
    
    @media (max-width: $smartphone) {
        margin-top: var(--padding-m);
        
        > div:first-child {
            margin-bottom: var(--padding-xxxs);
        }
    }

    @media (min-width: $smartphone) {
        display: flex;
        justify-content: space-between;
    }
}
