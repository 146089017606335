.info-btn,
.info-panel {
    --padding-v: var(--padding-xxs);
    --padding-h: var(--padding-xxs);
    --font-size: var(--font-size-xsmall);

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xxxsmall);
    }
}

.info-btn {
    @include z-index($z-index-sidemenu);
    position: absolute;
    left: 50vw;
    bottom: var(--padding-h);
    background-color: transparent;
    padding: 0;
    text-align: center;

    &::before {
        content: '+';
    }
    
    &.--active {
        &::before {
            content: '-';
        }
    }

    @include font-sans();
    font-size: var(--font-size);
}

.info-panel {
    @include z-index($z-index-windows);

    @include font-sans();
    font-size: var(--font-size);

    background-color: var(--white);
    position: fixed;
    left: 0;
    top: 50%;
    height: 50%;
    width: 100%;
    transform: translateY(100%);
    padding: var(--padding-v) var(--padding-h);

    overflow-y: auto;

    &__overlay {
        position: absolute;
        left: 0;
        top: 0;
        height: 100vh;
        width: 100vw;
        background-color: var(--blackOther);
        opacity: 0;
        padding: 0;
        
        &:not(.--active) {
            pointer-events: none;
        }
    }

    &__content {
        div:nth-child(2){
            text-indent: rem(40);
        }
    }

    .footer {
        margin-top: var(--padding-m);
    }

    @media (max-width: $smartphone) {
        padding-bottom: var(--padding-m);

        &__content {
            > div:first-child {
                margin-bottom: var(--padding-s);
            } 
        }

        .info-btn { display: none; }
    }

    @media (min-width: $smartphone) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__content {
            display: flex;
            align-items: flex-start;

            >  div:first-child {
                position: sticky;
                top: 0;
            } 

            > div {
                flex: 0 1 50%;
            } 
        }

        .footer {
            position: sticky;
            bottom: 0;
        }
    }
} 