.block-gallery {
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-xxs);
    --img-height: #{rem(405)};
    --img-width: #{rem(310)};
    --font-size: var(--font-size-xsmall);
    --y: 0;
    --counterH: #{rem(22)};
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-m);
        --img-height: #{rem(150)};
        --img-width: #{rem(115)};
        --counterH: #{rem(15)};
        --font-size: var(--font-size-xxxsmall);
    }
}

.block-gallery {
    padding: calc(var(--padding-v) + var(--header-height)) var(--padding-h); 
    height: 100vh;
    width: 100vw;
    
    display: flex;
    flex-direction: column;

    @include font-sans();
    font-size: var(--font-size);
    
    .images {
        flex-grow: 1;
        position: relative;
        
        -webkit-user-select: none;  /* Chrome all / Safari all */
        -moz-user-select: none;     /* Firefox all */
        -ms-user-select: none;      /* IE 10+ */
        user-select: none;          /* Likely future */
        -webkit-touch-callout: none;
        -moz-touch-callout: none;
        -ms-touch-callout: none;
        touch-callout: none;
        
        &:not(.--no-pointer) {
            cursor: pointer;
        }
    }
    
    .image {
        left: 0;
        top: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        pointer-events: none;
        transition: filter .2s ease-out;
        filter: blur(3px);

        figure {
            height: 100%;
            width: 100%;
        }
    
        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }

        > div {
            height: 100%;
        }
    }

    .counter {
        position: absolute;
        right: var(--padding-h);
        bottom: var(--padding-h);
        display: flex;

        > span {
            text-align: right;
            overflow: hidden;
            margin-left: var(--padding-xxxs);
            height: var(--counterH);
            
            > span {
                display: flex;
                flex-direction: column;
                transform: translateY(calc(var(--y) * 100%));
                transition: transform .1s ease-out;
            }
        }
    }

    // @media (min-width: $smartphone) {
    //     .images .image {
    //         &.--type-2 {
    //             > div {
    //                 width: 50%;

    //                 img {
    //                     object-fit: cover;
    //                 }
    //             }
    //         }

    //         &.--type-3 {
    //             > div {
    //                 width: 50%;
    //                 margin-left: 50%;

    //                 img {
    //                     object-fit: cover;
    //                 }
    //             }
    //         }

    //         &.--type-4 {
    //             > div {
    //                 width: 50%;
    //                 height: 50%;
    //                 left: 25%;
    //                 top: 25%;
    //                 position: absolute;

    //                 img {
    //                     object-fit: cover;
    //                 }
    //             }
    //         }
    //     }
    // }
}
