.widget-landing {
    --cols: 10;
    --rows: 8;
    // --gap: #{rem(10)};
    --gap: #{rem(5)};
    --width-image: #{rem(310)};
    --font-size: var(--font-size-xsmall);
    --font-size-name: var(--font-size-medium);
    --height: #{rem(415)};
    
    @media (max-width: $smartphone) {
        // --cols: 8;
        // --rows: 10;
        --gap: #{rem(6)};
        // --font-size: var(--font-size-xxxsmall);
        --font-size-name: var(--font-size-xsmall);
        --width-image: 100%;
        --height: auto;
    }
}

.widget-landing {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .wrapper {
        .item {
            @include basic-a();
            display: block; 
            position: relative;
            width: var(--width-image);
            cursor: pointer;

            * {
                -webkit-user-select: none;  /* Chrome all / Safari all */
                -moz-user-select: none;     /* Firefox all */
                -ms-user-select: none;      /* IE 10+ */
                user-select: none;          /* Likely future */
                -webkit-touch-callout: none;
                -moz-touch-callout: none;
                -ms-touch-callout: none;
                touch-callout: none;

                -webkit-user-drag: none;
                -khtml-user-drag: none;
                -moz-user-drag: none;
                -o-user-drag: none;
                user-drag: none;

                pointer-events: none;
            }

            .overlay {
                transform-origin: top center;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                pointer-events: none;

                span {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    pointer-events: none;
                }

                span:first-child {
                    background-color: var(--blue);
                    transform-origin: bottom center;
                    transform: scale3d(1, 0, 1);
                }
                
                span:last-child {
                    @include font-sans(1.2);
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    font-size: var(--font-size);
                    color: var(--primary-bg);
                    padding: var(--padding-xxs);
                    text-align: right;
                }
            }
        }

        @include isCursor() {
            .item .overlay {
                display: block;

                span:last-child {
                    opacity: 0;
                    transition: opacity .2s ease-out;
                }
            }
            
            .item:hover .overlay {
                span:last-child {
                    opacity: 1;
                }
            }
        }
        
        .menu {
            @include basic-a();
            color: var(--blue);
    
            position: relative;
            font-size: var(--font-size);

            min-height: var(--height);
    
            > * {
                min-width: 50%
            } 
    
            span {
                @include font-sans(1.2);
            }
    
            span:first-child {
                padding-left: var(--padding-xxs);
            }
    
            &::after {
                content: '';
                background-image: linear-gradient(0, var(--blue) 0%, transparent 100%);
                position: absolute;
                top: var(--gap);
                left: var(--gap);
                bottom: var(--gap);
                right: var(--gap);
            }
        }
    }
    
    @media (max-width: $smartphone) {
        .wrapper {
            padding: var(--gap);

            .menu {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                height: 50vh;

                span:last-child {
                    text-align: right;
                    padding-right: var(--padding-xxs);
                }
            }

            .item:not(:last-child) {
                margin-bottom: var(--gap);
            }

            .item .overlay {
                top: var(--gap);
                left: var(--gap);
                bottom: var(--gap);
                right: var(--gap);
            }
        }
    }

    @media (min-width: $smartphone) {
        height: 100vh;
        width: 100vw;
        
        .wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);

            > div {
                display: grid;
                grid-template-rows: repeat(var(--rows), auto);
                grid-template-columns: repeat(var(--cols), var(--width-image));
                width: calc(var(--width-image) * var(--cols));
            }

            .item {
                padding: var(--gap);
            }
    
            .menu {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
    
                span:last-child {
                    text-align: right;
                    padding-right: var(--padding-xxs);
                }
            }
        }
    }
}
