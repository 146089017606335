@use "sass:math";

#Header {
    --width: 100%;
    --height: var(--header-height);
    --color: var(--primary-color);
    --bg: transparent;
    --padding-v: var(--padding-xxxs);
    --padding-h: var(--padding-xxs);
    --font-size-logo: var(--font-size-medium);
    --font-size: var(--font-size-xsmall);

    @media (max-width: $smartphone) {
        --font-size-logo: var(--font-size-xxxsmall);
        --font-size: var(--font-size-xxxsmall);
    }
}

#Header {
    @include z-index($z-index-header);
    position: fixed;
    width: var(--width);
    height: var(--height);
    right: 0;
    top: 0;
    padding: var(--padding-v) var(--padding-h);

    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    color: var(--color);
    background-color: var(--bg);

    // transform: translate3d(0, var(--y-header), 10px);

    .logo {
        @include basic-a();
        @include font-sans(1);
        font-size: var(--font-size-logo);
        color: inherit;
    }

    a {
        @include basic-a();
        @include font-sans(1.2);
        font-size: var(--font-size);
        color: inherit;
    }

    nav {
        flex: 0 1 50%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        > * {
            flex: 0 1 50%;
        }

        a[aria-current="page"] {
            color: var(--blue);
            // font-style: italics;
        }
        
        @include isCursor () {
            a:hover {
                color: var(--blue);
            }
        }
    }
}
