.not-found {
    --font-size-logo: var(--font-size-medium);
    --font-size: var(--font-size-xsmall);

    @media (max-width: $smartphone) {
        --font-size-logo: var(--font-size-xxxsmall);
        --font-size: var(--font-size-xxxsmall);
        
        min-height: 95vh;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;

    a {
        @include basic-a();
        @include font-sans(1.2);
        font-size: var(--font-size);
        color: inherit;

        position: absolute;
        top: var(--padding-xxs);
    }

    span {
        @include basic-a();
        @include font-sans(1);
        font-size: var(--font-size-logo);
        color: inherit;
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        background: linear-gradient(180deg, transparent 25%, var(--blue) 100%);
        pointer-events: none;
    }

    @media (min-width: $smartphone) {
        min-height: 100vh;
    }
}
