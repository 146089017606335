:root {
    --white: #FAFAFA;
    --black: #000000;
    --black45: rgba(0, 0, 0, .45);
    --blackOther: rgba(217, 217, 217, 0.4);
    --blue: #0000F1;
    --blue80: rgb(0, 0, 241, .8);

    --primary: var(--black);
    --secondary: var(--white);

    --assertive: #ff00ff;
    --focus: #ff00ff;
    --color-error: #ff0000;
    --color-sucess: #4dc247;
}