#Preloader {
    --font-size: var(--font-size-s);
    --color: var(--primary-color);
    --bg: var(--primary-bg);
    --font-size: var(--font-size-medium);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xsmall);
    }

    // display: none !important;
}

#Preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    @include z-index($z-index-loader);
    color: var(--color);
    background-color: var(--bg);
    
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    
    @include font-sans(1);
    font-size: var(--font-size);
    text-transform: uppercase;
    
    .text {
        position: relative;
        transform: translateY(100%);
        font-size: var(--font-size);
    }
    
    .wrapper {
        display: flex;
        gap: var(--padding-xxxs);
        overflow: hidden;
    }
    
    .overlay {
        background-color: var(--blue);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transform-origin: bottom center;
    }
}
