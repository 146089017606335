.block-project-list {
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-xxs);
    --img-height: #{rem(405)};
    --img-width: #{rem(310)};
    --font-size: var(--font-size-medium);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-m);
        --img-height: #{rem(150)};
        --img-width: #{rem(115)};
        --font-size: var(--font-size-xsmall);
    }
}

.block-project-list {
    display: flex; 
    padding: calc(var(--padding-v) + var(--header-height)) var(--padding-h) var(--padding-v) ; 

    > * {
        flex: 0 1 50%;
    }

    .list li {
        a {
            @include basic-a();
            @include font-sans();
            font-size: var(--font-size);
            color: var(--primary-color);
            display: inline-block;
            overflow: hidden;
            padding-right: var(--padding-m);

            &.--active {
                color: var(--blue);
                // font-style: italic;
            }
            
            span {
                display: block;
                position: relative;
                transform: translateY(-100%);
            }
        }
    }

    .images {
        position: relative;
        overflow: hidden;
        height: var(--img-height);
        width: var(--img-width);
    }

    .overlay {
        @include z-index(2);
        transform-origin: top center;
        
        span {
            background-color: var(--blue);
            transform-origin: bottom center;
        }
    }
    
    .image,
    .overlay,
    .overlay span {
        left: 0;
        top: 0;
        position: absolute;
        height: var(--img-height);
        width: var(--img-width);
        pointer-events: none;
    }
    
    .image {
        @include z-index(1);

        figure {
            height: 100%;
            width: 100%;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    @include isCursor() {
        .list li a:hover {
            color: var(--blue);
            // font-style: italic;
        }
    }

    @media (min-width: $smartphone) {
        min-height: calc(100vh - var(--footer-height));
    }
}
