@keyframes pulse {
    0% {
        opacity: .4;
    }

    100% {
        opacity: 1;
    }
}

::selection {
  color: var(--blue);
}

html {
    width: 100%;
    height: 100%;
}

body {
    width: 100vw;
    min-height: 100%;
    color: var(--primary-color);
    background: var(--primary-bg);

    // overscroll-behavior-x: none;

    > video {
        visibility: hidden;
        pointer-events: none;
        height: 0;
        width: 0;
    }
    
    main {
        width: 100%;
    }
    
    &:not(.__scroll-manual) {
        overflow: hidden;
        height: 100vh;

        .wrap {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100vw;
        }
    }
    
    &.__scroll-manual {
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;

        &.__noScroll {
            overflow: hidden;
        }

        // main {
        //     @include z-index($z-index-wrap);
        //     position: fixed;
        //     height: 100%;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        // }
    }

     &.__home {
        overflow: hidden;

        #Main {
            @include z-index($z-index-wrap);
            position: fixed;
            height: 100%;
            top: 0;
            left: 0;
            width: 100%;

            .wrap {
                height: 100%;
                overflow: hidden;

                article {
                    height: 100%;
                }
            }
        }
    }
}

.icon {
    display: inline-block;

    svg {
        height: 100%;
        width: 100%;
    }
}

.line-parent {
    overflow: hidden;
}

*[aria-current="page"] {
    pointer-events: none;
    text-decoration: none;
}

button[disabled] {
    pointer-events: none;
    opacity: .3;
}

// Tweakpane
.tp-dfwv {
    @include z-index($z-index-tweakpane);
}

.__indicator {
    @include z-index($z-index-tweakpane);

    display: none;

    position: fixed;
    left: 0;
    top: 0;
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%, var(--blue) 0%, var(--blue80) 50%, transparent 100%);
    height: rem(175);
    width: rem(175);
    
    max-height: 200px;
    max-width: 200px;

    animation: pulse alternate .6s infinite;
    
    pointer-events: none;
    
    @media (max-width: $smartphone) {
        height: rem(120);
        width: rem(120);
    }
}


// 217.160.0.142