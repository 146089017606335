
.project-card {
    --font-size: var(--font-size-base);

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-small);
    }
}

.project-card {
    @include basic-a();
    color: var(--white);
    
    .name {
        @include font-sans();
        font-size: var(--font-size);
    }
    
    @media (max-width: $smartphone) {
        .body {
            padding: var(--padding-s) var(--padding-s) 0;
            margin-top: var(--padding-xxs);
        }
    }
    
    @media (min-width: $smartphone) {
        .body {
            margin-top: var(--padding-xs);
        }
    }
}
